import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ErrorBoundary, Theme, SizerWrapper, themeOptions, getThemeColor, GlobalStyle as BaseGlobalStyle } from '@commonsku/styles';
import withReducers from '../store/withReducers';
import { reducers } from '../store/configureDashboardByRoleStore';
import dashboardReducer from '../components/dashboard/dashboardSlice';
import { oauth } from '../utils';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import createPopup from '../popup-factory';
import { getPopups } from '../selectors';
import { createNewFeaturePopup } from '../actions/popup';
import { DashboardContainer } from '../components/dashboard';
import { mentionTextareaStyles, SKUFontDefinitionStyles } from '../components/helpers/createGlobalStyle';
import reportsReducer from '../redux/reports';
import usersReducer from '../redux/users';
import messagesReducer from '../redux/messages';
import notesReducer from '../redux/notes';
import statusReducer from '../redux/status';
import teamsReducer from '../redux/teams';
import { document } from '../global';
import ReactTooltip from 'react-tooltip';

/**
 * On component did mount
 * @param {Object} data
 */
function onInit({user_id, onCreateNewFeaturePopup}) {
  // remove resku from body and html tags
  document.getElementsByTagName('body')[0].className = '';
  document.getElementsByTagName('html')[0].className = '';
  document.body.style = "background: #EDF4F7;";
  oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
    if (json.show_popup == 1) {
      onCreateNewFeaturePopup();
    }
  });
}

const additionalGlobalStyle = (p) => `
${SKUFontDefinitionStyles}
${mentionTextareaStyles(p)}

.csku-styles {
  font-family: ${(p.theme && p.theme.fontFamily) || "'skufont-regular', 'sans-serif', 'Roboto'"};
}

[id^="chart-div-"] {
  font-family: "skufont-regular",sans-serif,Roboto;
}

.popup.projects-list-popup .popup-content {
  height: auto !important;
}

.popup.clients-list-popup .popup-content,
.popup.deposit-invoices-list-popup .popup-content {
  height: auto !important;
}

.project-table-list-rows, .table-list-rows {
  .tr:hover, .tr.selected {
    background: ${p.theme.colors.primary1['10']};
  }
}

.csku-styles {
  &.popup.production-list-popup,
  &.popup.projects-list-popup,
  &.popup.clients-list-popup,
  &.popup.deposit-invoices-list-popup {
    font-family: var(--font-family-regular);

    .popup-content > div {
      padding: 0;
    }
  }

  &.popup.production-list-popup {
    .popup-content {
      height: auto;
    }
  }

  a {
    color: #02C0DA;
    text-decoration: none;
  }
}

.popup.projects-list-analytics-popup .popup-content {
  height: auto !important;
}

.popup.projects-list-analytics-popup .popup-content .table .header-wrapper .header.tr{
  height: initial;
  background: initial;
  position: inherit;
  top: 0;
  left: 0;
  border-bottom: none;
  z-index: inherit;
}

.production-dashboard-tooltip {
  z-index: 10009;
}
.tasks-comment {
  .mention-textarea-container {
    textarea {
      height :50px !important 
    }
  }
} 
`;

export const GlobalStyle = (p) =>
  <BaseGlobalStyle {...p} additionalStyles={additionalGlobalStyle} />;

function DashboardApp({
  identity,
  onCreateNewFeaturePopup,
  popups,
  ...props
}) {
  useEffect(function () {
    onInit({user_id: identity.user_id, onCreateNewFeaturePopup});
  }, []);

  return (
    <Theme theme={themeOptions}>
      <GlobalStyle theme={themeOptions} />
      <ErrorBoundary>
        <div className="resku" style={{background: '#EDF4F7'}}>
          <Header>
            <div className="large-12 columns" style={{background: '#EDF4F7', display: 'none'}}></div>
          </Header>
        </div>
        <div>
          <div className="resku"><MainSection /></div>
          <div className="main-content csku-styles" style={{ marginTop: '0rem', background: '#EDF4F7', }}>
          <ReactTooltip id="commentTip" place="top" className='production-dashboard-tooltip'>View Comments</ReactTooltip>
                <ReactTooltip id ="pinTip" place="top" className='production-dashboard-tooltip'/>
                <ReactTooltip id="deleteTip" place="top" className='production-dashboard-tooltip'>Delete</ReactTooltip>
                <ReactTooltip id="editTaskTip" place="top" className='production-dashboard-tooltip'>Edit</ReactTooltip>
            <SizerWrapper xsStyle="padding-left: 0; margin-left: 0;" smStyle="margin-left: 165px; padding-right: 0 !important; padding-left: 10px !important;" mdStyle="padding-left: 10px;" style={{paddingRight: 0}}>
              <DashboardContainer dashboard_type={props.dashboard_type} />
            </SizerWrapper>
          </div>
          <div className="resku">
            {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
          </div>
        </div>
        <div className="resku">
          <Overlay popups={popups} />
        </div>
      </ErrorBoundary>
    </Theme>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    popups: getPopups(state),
    filterOptions: {
    },
    loading: state.display.loading,
    dashboard_type: state.entities.dashboard_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    },
  };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(DashboardApp), {
  ...reducers,
  reports: reportsReducer,
  users: usersReducer,
  messages: messagesReducer,
  notes: notesReducer,
  status: statusReducer,
  teams: teamsReducer,
  ...dashboardReducer,
}, true);
