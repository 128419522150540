import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';
import { window } from '../global';
import { supplierAccountReducer } from '../reducers/supplier';
import projectReducer from '../reducers/project';
import purchaseOrderReducer from '../reducers/purchase_order';
import displayReducer from '../reducers/display';
import tempReducer from '../reducers/temp';
import fileReducer from '../reducers/file';
import heroImageReducer from '../redux/heroImage';
import botsReducer from '../redux/chatBots';
import {
    proofReducer,
    shipmentReducer,
    reminderListReducer,
    purchaseOrderReducer as prPOReducer,
    jobReducer as prJobReducer,
    supplierAccountReducer as prSupplierAccountReducer,
    fileReducer as prFileReducer,
    folderReducer as prFolderReducer,
} from './configureProductionReportStore';
import {
    entitiesReducer as coreEntitiesReducer,
    dropdownsReducer,
    identityReducer,
} from '../reducers/core';

var initialState = typeof (initialState) === 'undefined' ? window.initialState : initialState;

const combinePRProjectsReducer = (state, action) => {
    return prJobReducer(
        projectReducer(state, action),
        action
    );
};

const combinedPRSupplierAccountReducer = (state, action) => {
    return prSupplierAccountReducer(
        supplierAccountReducer(state, action),
        action
    );
};

const combinedPRPurchaseOrderReducer = (state, action) => {
    return purchaseOrderReducer(
        prPOReducer(state, action),
        action
    );
};

const combinedPRFileReducer = (state, action) => {
    return prFileReducer(
        fileReducer(state, action),
        action
    );
};

const entitiesReducer = (state = initialState.entities || {}, action) => {
    return Object.assign({}, state, coreEntitiesReducer(state, action), {
        // from production report
        files: combinedPRFileReducer(state.files, action),
        folders: prFolderReducer(state.folders, action),
        po_proofs: proofReducer(state.po_proofs || {}, action),
        projects: combinePRProjectsReducer(state.projects, action),
        purchase_orders: combinedPRPurchaseOrderReducer(state.purchase_orders, action),
        reminder_lists: reminderListReducer(state.reminder_lists, action),
        shipments: shipmentReducer(state.shipments, action),
        supplier_accounts: combinedPRSupplierAccountReducer(state.supplier_accounts, action),
    });
};

export const reducers = {
    display: displayReducer,
    dropdowns: dropdownsReducer,
    entities: entitiesReducer,
    identity: identityReducer,
    heroImage: heroImageReducer,
    chatBots: botsReducer,
    temp: tempReducer,
};

const rootReducer = combineReducers(reducers);

export default function configureProductionReportStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
